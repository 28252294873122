import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from './ui/i18n'

import Error404 from './error-404'
import ExtensionContact from './ui/extension-contact'

import DatadogRUM from './DatadogRUM'
import store from './store'

import './App.css'

DatadogRUM()

const App = () => (
  <Provider store={store}>
    <IntlProvider>
      <Router>
        <Switch>
          <Route
            exact
            path="/extensions/contact"
            component={ExtensionContact} // eslint-disable-line
          />
          <Route component={Error404} />
        </Switch>
      </Router>
    </IntlProvider>
  </Provider>
)

export default App
